import { MutableRefObject, useEffect, useRef, useState } from 'react';

import { syncedDate } from '@utils/_helpers/_common';
import { IUseCountdownProps } from './_types';

/**
 * Хук таймера (обратного отсчёта). Принимает на вход:
 * @param {number} timeInMilliseconds - время в миллисекундах.
 * @param {boolean} autostart - нужно ли выполнять автозапуск таймера при инициализации.
 * @param {number} tickTime - время обновления значения таймера.
 * @param {Function} callback - коллбэк-функция, которая выполнится после дохождения до 0.
 *
 * @returns {number} milliseconds - время до конца таймера (в мс)
 * @returns {Function} setMilliseconds - фунция для установки времени таймера
 * @returns {Function} stopTimer - фунция для остановки таймера
 * @returns {Function} startTimer - фунция для старта таймера
 * @returns {Function} updateTimer - фунция для обновления времени таймера
 * @returns {boolean} paused - приостановлен ли таймер
 */
export function useCountdown({
  timeInMilliseconds,
  autostart = true,
  tickTime = 1000,
  callback,
}: IUseCountdownProps) {
  const intervalRef: MutableRefObject<number | null> = useRef(null);

  const [milliseconds, setMs] = useState(Math.max(timeInMilliseconds, 0));
  const [paused, setPaused] = useState(true);

  function setMilliseconds(milliseconds: number): void {
    setMs(Math.max(milliseconds, 0));
  }

  function stopTimer(): void {
    if (intervalRef.current) {
      setPaused(true);
      setPaused(true);
      window.clearInterval(intervalRef.current);
      intervalRef.current = null;
      setPaused(true);
    }
  }

  function countDown(endTime: number): void {
    setMs(() => {
      let decrement = endTime - syncedDate().getTime();

      if (decrement <= 0) {
        decrement = 0;
        stopTimer();

        if (callback) {
          callback({ setMilliseconds: setMs, startTimer, resetTimer });
        }

        setPaused(true);
      }

      return decrement;
    });
  }

  function startTimer(startMs?: number | null): void {
    const ms = startMs ? startMs : milliseconds;

    if (ms && !intervalRef.current) {
      setPaused(false);
      setMilliseconds(ms);
      setPaused(false);
      setMilliseconds(ms);
      const endTime = syncedDate().getTime() + ms;

      setMilliseconds(endTime - syncedDate().getTime());
      intervalRef.current = window.setInterval(
        () => countDown(endTime),
        tickTime
      );
      setPaused(false);
    }
  }

  function resetTimer(): void {
    stopTimer();
    setMilliseconds(0);
  }

  function updateTimer(ms: number): void {
    if (paused) {
      setMilliseconds(ms);
    } else {
      stopTimer();

      setMilliseconds(ms);
      setMilliseconds(ms);
      const endTime = syncedDate().getTime() + ms;

      setMilliseconds(endTime - syncedDate().getTime());
      intervalRef.current = window.setInterval(
        () => countDown(endTime),
        tickTime
      );
      setPaused(false);
    }
  }

  useEffect(() => {
    return function cleanup() {
      resetTimer();
    };
  }, []);

  useEffect(() => {
    if (intervalRef.current === null && autostart) {
      startTimer();
    }
  }, [milliseconds]);

  return {
    milliseconds,
    setMilliseconds,
    stopTimer,
    startTimer,
    updateTimer,
    paused,
  };
}
